
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~angular-notifier/styles/core.css';
@import '~angular-notifier/styles.css';

/* Fonts */
@font-face {
	font-family: 'Montserrat-Bold';
	src: url('../src/assets/fonts/Montserrat-Bold/Montserrat-Bold.eot');
	src: url('../src/assets/fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
			url('../src/assets/fonts/Montserrat-Bold/Montserrat-Bold.woff2') format('woff2'),
			url('../src/assets/fonts/Montserrat-Bold/Montserrat-Bold.woff') format('woff'),
			url('../src/assets/fonts/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-ExtraLight';
	src: url('../src/assets/fonts/Montserrat-ExtraLight/Montserrat-ExtraLight.eot');
	src: url('../src/assets/fonts/Montserrat-ExtraLight/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
			url('../src/assets/fonts/Montserrat-ExtraLight/Montserrat-ExtraLight.woff2') format('woff2'),
			url('../src/assets/fonts/Montserrat-ExtraLight/Montserrat-ExtraLight.woff') format('woff'),
			url('../src/assets/fonts/Montserrat-ExtraLight/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-Light';
	src: url('../src/assets/fonts/Montserrat-Light/Montserrat-Light.eot');
	src: url('../src/assets/fonts/Montserrat-Light/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
			url('../src/assets/fonts/Montserrat-Light/Montserrat-Light.woff2') format('woff2'),
			url('../src/assets/fonts/Montserrat-Light/Montserrat-Light.woff') format('woff'),
			url('../src/assets/fonts/Montserrat-Light/Montserrat-Light.svg#Montserrat-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-Medium';
	src: url('../src/assets/fonts/Montserrat-Medium/Montserrat-Medium.eot');
	src: url('../src/assets/fonts/Montserrat-Medium/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
			url('../src/assets/fonts/Montserrat-Medium/Montserrat-Medium.woff2') format('woff2'),
			url('../src/assets/fonts/Montserrat-Medium/Montserrat-Medium.woff') format('woff'),
			url('../src/assets/fonts/Montserrat-Medium/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-Regular';
	src: url('../src/assets/fonts/Montserrat-Regular/Montserrat-Regular.eot');
	src: url('../src/assets/fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
			url('../src/assets/fonts/Montserrat-Regular/Montserrat-Regular.woff2') format('woff2'),
			url('../src/assets/fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff'),
			url('../src/assets/fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-SemiBold';
	src: url('../src/assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.eot');
	src: url('../src/assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
			url('../src/assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff2') format('woff2'),
			url('../src/assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff') format('woff'),
			url('../src/assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-Thin';
	src: url('../src/assets/fonts/Montserrat-Thin/Montserrat-Thin.eot');
	src: url('../src/assets/fonts/Montserrat-Thin/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
			url('../src/assets/fonts/Montserrat-Thin/Montserrat-Thin.woff2') format('woff2'),
			url('../src/assets/fonts/Montserrat-Thin/Montserrat-Thin.woff') format('woff'),
			url('../src/assets/fonts/Montserrat-Thin/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
}
html, 
body { 
	height: 100%; 
}
body { 
	margin: 0; font-family: "Montserrat-Regular", Roboto, sans-serif; 
}
.mat-drawer-container {
	height: 100%;
}
.mat-toolbar {
	/* background-color: rgb(38, 151, 212); */
	background-color: #333a4a;
  border-bottom: 1px solid rgba(0,0,0,.87);
}
.nav_tog {
	color: #fff;
}
.remaining-space {
	flex: 1 1 auto;
}
.logout {
	float: right;
	color: #fff;
	outline: unset;
	font-family: 'Montserrat-Bold';
	border: unset;
	background: unset;
	cursor: pointer;
}
button:focus {
	outline: unset;
	border: unset;
}
.mat-menu-item.logout:hover:not([disabled]) {
	background: unset;
}
.mat-drawer {
	/* background-color: #fafafa; */
	background-color: #293a4c;
}
.mat-drawer-container {
	background-color: #fff;
	/* background-color: #485879; */
}
.mat-nav-list .mat-list-item:focus, 
.mat-nav-list .mat-list-item:hover{
	background: unset;
}
.page-title{
	/* color: #fff; */
	margin: 30px 25px;
}
.side-nav {
	color: #fff;
	font-family: 'Montserrat-Regular';
	cursor: pointer;
}
.side-nav:hover {
	color: #fff;
	text-decoration: unset;
}
.nav-user .mat-list-item-content,
.nav-dash .mat-list-item-content,
.nav-system .mat-list-item-content,
.nav-markets .mat-list-item-content,
.nav-buysell .mat-list-item-content,
.nav-savings  .mat-list-item-content{
	margin-top: 20px;
	padding-left: 10px;
	padding-bottom: 10px;
}
.nav-coin .mat-list-item-content {
	padding: 10px 0;
}
.table thead th {
	border-bottom: 1px solid #dee2e6;
}
.pop-adjust {
	margin-right: 30px;
}
.modal.show .modal-dialog {
	position: relative;
	margin: 0 auto;
	top: 25%;
}
i.material-icons.nav-icons {
	position: relative;
	top: 4px;
	right: 4px;
}
.nav-coin-img {
	position: relative;
  right: 6px;
}
.side-nav.mat-list-item.active .mat-list-item-content {
  background-color: #485879;
  color: #fff;
}
.navbar {
	padding: 0px;
}
li.dropdown.side-nav.nav-coins {
	margin-left: 10px;
	margin-top: 20px;
}
a.side-nav.nav-coin.mat-list-item {
	text-align: center;
}
.intext {
	border-radius: 25px;
   padding: 6px;
  border: 1px solid darkgrey;
}
.intext:focus {
	outline: unset;
}
@media only screen and (min-width: 320px) and (max-width: 736px) {
.page-title {
    font-size: 20px;
    text-align: center;
}
}
.cdk-overlay-container {
  z-index: 1200;
}